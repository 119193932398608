import { render, staticRenderFns } from "./SuperAdminIndex.vue?vue&type=template&id=4d4029dc&scoped=true&"
import script from "./SuperAdminIndex.vue?vue&type=script&lang=js&"
export * from "./SuperAdminIndex.vue?vue&type=script&lang=js&"
import style0 from "./SuperAdminIndex.vue?vue&type=style&index=0&id=4d4029dc&lang=less&scoped=true&"
import style1 from "./SuperAdminIndex.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d4029dc",
  null
  
)

export default component.exports