<template>
  <div class="superAdmin">
    <el-container style="height: 100vh; ">
      <el-aside class="superAside" width="130px">
        <div class="avatar">
          <img width="100%" :src="logo" alt />
        </div>
        <p style="text-align:center">{{superName?superName:'暂无姓名'}}</p>
        <el-menu
          style="margin-top:20px;"
          :default-active="$route.path"
          active-text-color="rgb(64,158,255)"
          router
        >
          <el-menu-item index="/organization">超级后台管理</el-menu-item>
          <el-menu-item index="/taskManage">任务管理</el-menu-item>
          <el-submenu index="">
            <template slot="title">
              <span style="color:white">需求市场</span>
            </template>
            <el-menu-item index="/demandMarketAdmin">需求类别</el-menu-item>
            <el-menu-item index="/demandMarketList">需求管理</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header class="superHeader" style="width:100%;text-align: right; font-size: 12px;">
          <el-dropdown @command="showselsect">
            <span class="el-dropdown-link">
              <!-- {{superName?superName:'暂无姓名'}} -->
              <i class="el-icon-setting el-icon--right" style="margin-right: 15px;color:white"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="edit">修改密码</el-dropdown-item>
              <el-dropdown-item command="quit">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-header>
        <el-main>
          <el-button :type="btnType1" @click="changeTab('btnType1','btnType2','Organization')">机构列表</el-button>
          <el-button :type="btnType2" @click="changeTab('btnType2','btnType1','mapIndex')">地图导航</el-button>
          <div style="margin-top:30px;">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      superName: "",
      logo: "",
      btnType1: "primary",
      btnType2: "plain"
    };
  },
  created() {
    let superToken = sessionStorage.getItem("superToken");
    if (!superToken || superToken === undefined) {
      this.$router.push({
        name: "superLogin"
      });
      return false;
    }
    // if (localStorage.superName && localStorage.superName != undefined) {
    //   this.superName = localStorage.superName;
    // }
    if (sessionStorage.superName) {
      this.superName = sessionStorage.superName;
    }
    if (sessionStorage.superImg) {
      this.logo = sessionStorage.organizationImg;
    }
    let cur_path = this.$route.path; //获取当前路由
    cur_path = cur_path.substring(1);
    if (cur_path === "mapIndex") {
      this.changeTab("btnType2", "btnType1", "mapIndex");
    }
  },
  methods: {
    changeTab(tab1, tab2, jump) {
      this[tab1] = "primary";
      this[tab2] = "plain";
      this.$router.push({
        name: jump
      });
    },
    //头部菜单下拉选择
    showselsect(command) {
      if (command === "quit") {
        sessionStorage.removeItem("superToken");
        sessionStorage.removeItem("superName");
        sessionStorage.removeItem("superId");
        sessionStorage.removeItem("superImg");
        this.$router.push({
          name: "superLogin"
        });
      } else if (command === "edit") {
        this.$router.push({
          name: "forgetPassword"
        });
      }
    }
  }
};
</script>
<style lang="less"  scoped>
.superAdmin {
  width: 100%;
  .superHeader {
    background: #273e55;
    color: rgb(250, 234, 234);
    line-height: 60px;
  }

  .superAside {
    color: rgb(250, 234, 234);
    background: #273e55;
  }
  .avatar {
    margin: 15px auto 10px;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: rgb(245, 246, 248);
  }
  .admin-router {
    text-align: center;
    margin-top: 40px;
    a {
      color: white;
    }
  }
  //侧边导航背景
  .el-menu {
    background: #273e55;
  }
  .el-menu-item {
    color: white;
  }
  .el-menu-item:hover {
    color: rgb(64, 158, 255);
  }
  .el-submenu {
    .el-menu-item {
      background-color: #273e55;
    }
    .el-menu-item:hover {
      background-color: white;
    }
    .is-active {
      background-color: white;
    }
  }
}
</style>
<style lang="less">
  .superAdmin {
    .el-submenu {
      .el-submenu__title:hover{
        background-color: #273e55;
      }
    }    
  }
</style>


